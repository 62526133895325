@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* purgecss start ignore */
@font-face {
  font-family: 'Bebas Kai';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Bebas Kai'), url('/font/BebasKai.woff2') format('woff2'),
    url('/font/BebasKai.woff') format('woff'),
    url('/font/BebasKai.ttf') format('ttf');
  /* unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD; */
}

@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Calibri'), url('/font/CalibriRegular.ttf') format('ttf');
  /* unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD; */
}

html,
body {
  font-family: 'Calibri', sans-serif;
  scroll-behavior: smooth;
  /* overflow-y: scroll; */
}

h1,
h2,
h3,
h4,
h5,
h6,
.bebas {
  font-family: 'Bebas Kai', sans-serif !important;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29abe2;
  position: fixed;
  z-index: 1060;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29abe2, 0 0 5px #29abe2;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29abe2;
  border-left-color: #29abe2;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hbig {
  height: 41rem;
}

button:focus {
  outline: none;
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
}

/* Clase usada para que el contenido de la página no quede detrás del sidebar o navbar */
.main {
  padding-left: 11rem;
  padding-top: 40px;
  background-color: #030819;
  min-height: 100vh;
  overflow-x: hidden;
  /* overflow-y: scroll; */
}

tr th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
tr th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

body::-webkit-scrollbar {
  width: 0.5rem;
}

body {
  scrollbar-width: thin;
  scrollbar-color: #29abe2 #030819;
}

body::-webkit-scrollbar-track {
  background-color: #030819;
}

body::-webkit-scrollbar-thumb {
  background-color: #29abe2;
  border-radius: 0.25rem;
  border: #030819 2px solid;
}

/* .selectdiv {
  position: relative;

}

.selectdiv:after {
    content: '';
    position: absolute;
    pointer-events: none;
    background-image: url('/down.png');
    background-repeat: no-repeat;
}

select::-ms-expand {
display: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  display: block;
  width: 100%;
  float: right;
  background-color: transparent;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
} */

.modal-scrollbar::-webkit-scrollbar {
  width: 0.5rem;
}

.modal-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #29abe2 #030819;
}

.modal-scrollbar::-webkit-scrollbar-track {
  background-color: #030819;
}

.modal-scrollbar::-webkit-scrollbar-thumb {
  background-color: #29abe2;
  border-radius: 0.25rem;
  border: #030819 2px solid;
}

.anchor {
  padding-top: 5.2rem;
  margin-top: -5.2rem;
}

.scroll::-webkit-scrollbar {
  width: 1px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #101424;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #29abe2;
}

.scrolly::-webkit-scrollbar {
  width: 2px;
}

.scrolly::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #101424;
}

.scrolly::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #123048;
}

.scrollrequest::-webkit-scrollbar {
  width: 5px !important;
  scroll-behavior: smooth;
}

.scrollrequest::-webkit-scrollbar-track {
  background-color: #123048 !important;
  border-radius: 1px;
}

.scrollrequest::-webkit-scrollbar-thumb {
  background-color: #29abe2 !important;
  border-radius: 1px;
}

.last {
  text-align-last: center;
}
.change-first::select option:first-child {
  color: gray;
}

.selectdiv {
  position: relative;
}

.selectdiv:after {
  content: '˯';
  color: #0ebeff;
  padding-bottom: 10px;
  position: absolute;
}

select::-ms-expand {
  display: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: white;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
}

select option {
  background-color: #030819;
  color: #ffffff;
}

input[type='date']::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('/calendar.png') no-repeat;
  width: 15px;
  height: 15px;
  border-width: thin;
}
input[type='checkbox']:checked {
  background: url('/blackcheck.png') no-repeat;
  border-color: transparent;
  background-size: 100% 100%;
  background-position: center;
}

input[type='time' i]::-webkit-calendar-picker-indicator {
  background-image: url('/clock.png');
  background-origin: content-box;
  background-repeat: no-repeat;
  background-size: contain;
  height: 1.05em;
  margin-inline-start: 8px;
  opacity: 1;
  outline: none;
  padding-bottom: 3px;
  padding-inline-start: 3px;
  padding-inline-end: 3px;
  padding-top: 3px;
  width: 1.05em;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Centra el input personalizado con el texto */
.custom-radio-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-family: arial;
}

.radio__input > input {
  opacity: 0;
  width: 1em;
  height: 1em;
  position: fixed;
  z-index: 10;
}

.radio__control {
  display: grid;
  place-items: center;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.1em solid #29abe2;
}

input + .radio__control::before {
  content: '';
  width: 0.5em;
  height: 0.5em;
  box-shadow: inset 0.5em 0.5em #29abe2;
  border-radius: 50%;
  transition: 180ms transform ease-in-out;
  transform: scale(0);
}
input:checked + .radio__control::before {
  transform: scale(1);
}
.radio__input {
  display: flex;
  width: full;
  height: full;
  justify-content: center;
  align-items: center;
}

body {
  background: #eee;
}
.container {
  width: 40%;
  margin: 30px auto;
}
.tag-container {
  border: 2px solid #ccc;
  border-radius: 3px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 6px;
  overflow-x: scroll;
}
.tag-container .tag {
  height: 30px;
  margin: 5px;
  padding: 5px 6px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #eee;
  display: flex;
  align-items: center;
  color: #333;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), inset 0 1px 1px #fff;
  cursor: default;
}
.tag i {
  font-size: 16px;
  color: #666;
  margin-left: 5px;
}
.tag-container input {
  padding: 5px;
  font-size: 16px;
  border: 0;
  outline: none;
  font-family: 'Rubik';
  color: #333;
  flex: 1;
}
.badge {
  position: absolute;
  top: -3px;
  right: -3px;
  height: 5;
  width: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: xx-small;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  appearance: none;
  box-shadow: none;
}
.opti {
  color: #29abe2;
}

.opti em {
  color: #29abe2;
  font-size: x-small;
  font-style: normal;
  line-height: 1.25;
}

.datepicker-toggle {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 19px;
}
.datepicker-toggle-button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('/calendar.png') no-repeat;
}
.datepicker-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.datepicker-input::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.scrollable {
  height: -moz-calc(100% - 15rem);
  height: -webkit-calc(100% - 15rem);
  height: calc(100% - 15rem);
}
.scrollable2 {
  height: -moz-calc(100% - 20rem);
  height: -webkit-calc(100% - 20rem);
  height: calc(100% - 20rem);
}
.scrollable3 {
  height: -moz-calc(100% - 19rem);
  height: -webkit-calc(100% - 19rem);
  height: calc(100% - 19rem);
}

@media only screen and (max-width: 768px) {
  .scrollable2 {
    height: -moz-calc(100% - 16rem);
    height: -webkit-calc(100% - 16rem);
    height: calc(100% - 16rem);
  }
  .scrollable3 {
    height: -moz-calc(100% - 17rem);
    height: -webkit-calc(100% - 17rem);
    height: calc(100% - 17rem);
  }

  /* All Tables */

  table.projecttable,
  table.step2,
  table.selecttable,
  table.projecttable thead,
  table.step2 thead,
  table.selecttable thead,
  table.projecttable tbody,
  table.step2 tbody,
  table.selecttable tbody,
  table.projecttable th,
  table.step2 th,
  table.selecttable th,
  table.projecttable td,
  table.step2 td,
  table.selecttable td,
  table.projecttable tr,
  table.step2 tr,
  table.selecttable tr {
    display: block;
  }

  table.projecttable thead tr,
  table.step2 thead tr,
  table.selecttable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  table.projecttable tr td:first-child,
  table.step2 tr td:first-child,
  table.selecttable tr td:first-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  table.projecttable tr td:last-child,
  table.step2 tr td:last-child,
  table.selecttable tr td:last-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  table.projecttable td,
  table.step2 td,
  table.selecttable td {
    position: relative;
    padding-left: 50%;
  }

  table.projecttable td:before,
  table.step2 td:before,
  table.selecttable td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  table.projecttable td,
  table.step2 td,
  table.selecttable td {
    border-top: 0.5px solid #123048;
    border-bottom: 0.5px solid #123048;
  }

  table.projecttable td:first-child,
  table.step2 td:first-child,
  table.selecttable td:first-child {
    border-top: none;
  }
  table.projecttable td:last-child,
  table.step2 td:last-child,
  table.selecttable td:last-child {
    border-bottom: none;
    margin-bottom: 1.5rem;
  }

  table.projecttable td,
  table.step2 td,
  table.selecttable td {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  /* Project Table */

  table.projecttable td:nth-of-type(1):before {
    content: 'Código';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.projecttable td:nth-of-type(2):before {
    content: 'Cliente';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.projecttable td:nth-of-type(3):before {
    content: 'Proyecto';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.projecttable td:nth-of-type(4):before {
    content: 'Tipo';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.projecttable td:nth-of-type(5):before {
    content: 'Fecha';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.projecttable td:nth-of-type(6):before {
    content: 'Ejecutivo';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.projecttable td:nth-of-type(7):before {
    content: 'Project Manager';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.projecttable td:nth-of-type(8):before {
    background-image: url('/one.png');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: '';
    padding-left: 3px;
  }
  table.projecttable td:nth-of-type(9):before {
    background-image: url('/check.png');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: '';
    padding-left: 3px;
  }

  /* Step2 Table */

  table.step2 td:nth-of-type(1):before {
    content: 'Código';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.step2 td:nth-of-type(2):before {
    content: 'Proveedor';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.step2 td:nth-of-type(3):before {
    content: 'Contacto';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.step2 td:nth-of-type(4):before {
    content: 'Teléfono';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.step2 td:nth-of-type(5):before {
    content: 'Descuento';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.step2 td:nth-of-type(6):before {
    content: 'Eliminar';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.step2 td:nth-of-type(7):before {
    content: 'Resumen';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.step2 td:nth-of-type(8):before {
    content: 'Status';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }

  /* SelectTable */

  table.selecttable td:nth-of-type(1):before {
    content: 'Empresa';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.selecttable td:nth-of-type(2):before {
    content: 'Categoría';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.selecttable td:nth-of-type(3):before {
    content: '%';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.selecttable td:nth-of-type(4):before {
    content: 'Resumen';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
  table.selecttable td:nth-of-type(5):before {
    content: 'Seleccionar';
    color: #29abe2;
    text-align: left;
    padding-left: 3px;
  }
}

.tablescroll th {
  position: sticky;
  top: -1px;
  /* background-color: #3f4455; */
}

.changing em {
  color: #29abe2;
  font-style: normal;
}

/* purgecss end ignore */
